import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React from "react";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation?: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  landingPagesData:any;
  categoriesArray:any[];
  categoriesOnHoverList:any;
  brandsAndCataloguesList:any;
  error:boolean;
  scrollStates: {
    [key: number]: {
      canScrollLeft: boolean;
      canScrollRight: boolean;
    };
  };
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class LandingPageWebController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getLandingPagesApiCallId:string="";
  getCategoriesListApiCallId:string="";
  getHoverCategoriesList:string="";
  getBrandsAndCatalouguesDataId:string=""
  containerRefs: { [key: number]: React.RefObject<HTMLDivElement> } = {};
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
    ];
    this.state = {
      // Customizable Area Start
      landingPagesData:[],
      categoriesArray:[],
      categoriesOnHoverList:{},
      brandsAndCataloguesList:{},
      error:false,
      scrollStates: {
        0: { canScrollLeft: false, canScrollRight: true },
        1: { canScrollLeft: false, canScrollRight: true },}
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const dataId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    
      if (this.getLandingPagesApiCallId === dataId) {
        this.handleResponse(this.getLandingPagesApiCallId, 'landingPagesData', message);
      } else if (this.getCategoriesListApiCallId === dataId) {
        this.handleResponse(this.getCategoriesListApiCallId, 'categoriesArray', message);
      } else if (this.getHoverCategoriesList === dataId) {
        this.handleResponse(this.getHoverCategoriesList, 'categoriesOnHoverList', message);
      } else if (this.getBrandsAndCatalouguesDataId === dataId) {
        this.handleResponse(this.getBrandsAndCatalouguesDataId, 'brandsAndCataloguesList', message);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    Object.keys(this.containerRefs).forEach((_, index) => {
      setTimeout(() => this.checkScrollButtons(index), 0);
    });
   this.getLandingPagesApiCallId= this.apiCall("",{
        method:"GET",
        endPoint:"/bx_block_content_management/landing_pages"
    })
    this.getCategoriesListApiCallId= this.apiCall("",{
      method:"GET",
      endPoint:"/bx_block_categories/categories"
  })
  this.getBrandsAndCatalouguesDataId= this.apiCall("",{
    method:"GET",
    endPoint:"/bx_block_catalogue/catalogues/new_arrivals"
})
    // Customizable Area End
  }
  getCategoriesOnHover=(categorID:string|number)=>{
    if(categorID!=0){
      this.getHoverCategoriesList=this.apiCall("",{
        method:"GET",
        endPoint:`/bx_block_categories/categories/categories_hover_options?category_id=${categorID}`
      })
    }
  }
  apiCall = (token: any,payload:any) => {
    let {method,endPoint}=payload
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: token||"",
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
     method
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
   return requestMessage.messageId;

  };
// Assign ref to the correct index
assignRef = (index: number) => {
  // Create a new ref if it doesn't exist
  if (!this.containerRefs[index]) {
    this.containerRefs[index] = React.createRef<HTMLDivElement>();
  }
  // Return the ref object to be used in the JSX ref attribute
  return this.containerRefs[index];
};


// Scroll Left
scrollLeft = (index: number) => {
  const ref = this.containerRefs[index];
  if (ref.current) {
    ref.current.scrollBy({ left: index === 1 ? -700 : -200, behavior: 'smooth' });
    setTimeout(() => this.checkScrollButtons(index), 300); // Delay for smooth scroll
  }
};

scrollRight = (index: number) => {
  const ref = this.containerRefs[index];
  if (ref.current) {
    ref.current.scrollBy({ left: index === 1 ? 700 : 500, behavior: 'smooth' });
    setTimeout(() => this.checkScrollButtons(index), 500); // Delay for smooth scroll
  }
};
checkScrollButtons = (index: number) => {
  const ref = this.containerRefs[index];
  if (ref.current) {
    const { scrollLeft, scrollWidth, clientWidth } = ref.current;

    this.setState((prevState) => ({
      scrollStates: {
        ...prevState.scrollStates,
        [index]: {
          canScrollLeft: scrollLeft > 0,
          canScrollRight: scrollLeft + clientWidth < scrollWidth, // Check if there's more content to the right
        },
      },
    }));
  }
};
handleResponse = (apiCallId:string, stateKey:any, message:any) => {
  const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
  
  if (responseJson && !responseJson.errors) {
    this.setState({ [stateKey]: responseJson.data || responseJson }as Pick<S, keyof S>);
  } else if (responseJson && responseJson.errors) {
    this.setState({ error: true });
  }
};

  // Customizable Area End
}
