import React from "react";

// Customizable Area Start
import {Box, Button, Grid, InputLabel, TextField, Checkbox} from '@mui/material';
import { eyeIcon, eyeCloseIcon, checkBox, checkedBox } from "./assets";
import {configJSON} from "./EmailAccountLoginController";
// Customizable Area End

import EmailAccountLoginController, {
  Props,
} from "./EmailAccountLoginController";

export default class EmailAccountLoginBlock extends EmailAccountLoginController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      // Required for all blocks
      <Box sx={styles.root}>
        <Box style={styles.welcome}>
          <label style={styles.welcomeText} className="montserratFont">Welcome back!</label>
        </Box>
        <Box style={styles.formMain}>
        <Grid spacing={2}>
          <form 
          data-testid="loginBtn">
            <Grid item xs={12} sm={12} lg={12}>
              <InputLabel className="montserratFont">{configJSON.placeHolderEmail}</InputLabel>
              <TextField
              sx={{
                  "& fieldset": { border: this.state.emailIdError ? '1px solid red':'none' },
                  '& .MuiOutlinedInput-root': {
                      '&.Mui-focused fieldset': {
                          border: '1px solid #53503F',
                      },
                  },
              }}
              fullWidth
              name='email'
              placeholder={configJSON.emailPlaceholder}
              value={this.state.emailId}
              onChange={this.handleEmail}
              style={styles.inputField}
              type='email'
              data-testid='emailTestId'
              />
              {this.state.emailIdError ? (
              <p style={styles.error} id='emailError' className='montserratFont'>
                {this.state.emailIdError}
              </p>
              ) : null
              }
              {this.state.emailErrorBackend ? (
              <p style={styles.error} id='emailError' className='montserratFont'>
                {this.state.emailErrorBackend}
              </p>
              ) : null
              }
            </Grid>
            <Grid item xs={12} sm={12} lg={12}>
              <InputLabel className="montserratFont">{configJSON.placeHolderPassword}</InputLabel>
              <Box sx={styles.passwordField}>
              <TextField
              sx={{
                  "& fieldset": { border: this.state.passwordDataError ? '1px solid red':'none' },
                  '& .MuiOutlinedInput-root': {
                      '&.Mui-focused fieldset': {
                          border: '1px solid #53503F',
                      },
                  },
                  '& .MuiInputBase-input::placeholder': {
                  },
              }}
              fullWidth
              name='password'
              placeholder={configJSON.placeholderPass}
              value={this.state.passwordData}
              onChange={this.handlePassword}
              style={styles.inputField}
              type={this.state.eyeIcon ? 'text' : 'password'}
              data-testid='passwordTestId'
              />
              {this.state.passwordDataError ? (
              <p style={styles.error} id='emailError' className='montserratFont'>
                {this.state.passwordDataError}
              </p>
              ) : null
              }
              {this.state.passwordErrorBackend ? (
              <p style={styles.error} id='emailError' className='montserratFont'>
                {this.state.passwordErrorBackend}
              </p>
              ) : null
              }
                <Box data-testid="eyeIconTestId" onClick={this.handleEyeIcon} sx={this.state.passwordDataError || this.state.passwordErrorBackend ? styles.eyeIconWithError : styles.eyeIconMainWithOutError}>
                  {this.state.eyeIcon ?
                  <img src={eyeIcon} alt="eyeIcon"/>:
                  <img src={eyeCloseIcon} alt="eyeIcon"/>
                  }
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} lg={12}>
              <Box style={styles.rememberMain}>
                <Box style={styles.remember}>
                <Checkbox 
                  sx={{
                    padding: 0,
                    "&.Mui-checked": {
                      color: '#53503F'
                  }}} 
                  icon={<img src={checkBox} alt='checkbox' />}
                  checkedIcon={<img src={checkedBox} alt='checkedBox'/>}
                  value={this.state.rememberMe}
                  onChange={this.handleRememberMe}
                  name="termsCondition"
                  data-testid='rememberMeTestId'
                />
                  <p className="montserratFont" style={{color:"#53503F",fontSize: "12px",fontWeight: 600,padding: '0px 10px'}}>{configJSON.labelRememberMe}</p>
                </Box>
                <Box>
                  <span className="montserratFont" style={{
                                fontSize: "12px",
                                fontWeight: "600",
                                lineHeight: "24px",
                                textAlign: "left",
                                cursor:"pointer",
                                color:"#53503F"}}
                        onClick={this.props.handleOpenForgotModal}>
                          Forgot Password?
                  </span>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} lg={12}>
              <Button 
              disabled={!this.state.emailId || !this.state.passwordData} 
              onClick={this.handleSubmit} data-testid="loginTestId" variant="contained" 
              sx={{
                ...styles.btn, 
                '&.Mui-disabled': {
                  backgroundColor: '#53503F', 
                  color: '#EDE6DC', 
                  cursor: 'not-allowed',
                  opacity: 0.5
                },
              }}
              >Log In</Button>
            </Grid>
          </form>
        </Grid> 
        </Box>
      </Box>
      // Customizable Area End
    );
  }

}

// Customizable Area Start

const styles = ({
  logo: {
    padding: '10px'
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '16px',
    backgroundColor: '#EFEEE9', 
    borderRadius: 8,
  },
  inputField: {
    marginTop: '5px',
    marginBottom: '10px',
    backgroundColor: '#fff',
    border: 'none',
  },
  formMain: {
    width: '100%'
  },
  passwordField: {
    position: 'relative'
  },
  eyeIconMainWithOutError: {
    position: 'absolute',
    right: '15px',
    bottom: '40%'
  },
  rememberMain: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  welcome: {
    margin: '10px',
    paddingBottom: '50px'
  },
  eyeIconWithError: {
    position: 'absolute',
    right: '15px',
    bottom: '56%'
  },
  remember: {
    display: 'flex',
  },
  fieldLabel: {
    fontFamily: "Avenir",
    fontSize: '10px',
    fontWeight: '800',
    lineHeight: '24px',
    textAlign: "left",
    color: '#53503F'
  }, 
  welcomeText: {
    fontFamily: "Avenir",
    fontSize: '20px',
    fontWeight: '400',
    color: '#53503F'
  },
  error: {
    margin: '0px',
    fontSize: "12px",
    fontWeight: 800,
    color: 'red',
    paddingBottom: '15px'
  },
  btn: {
    backgroundColor: '#53503F',
    color: '#fff',
    marginTop: '10px',
    paddingTop: '10px',
    paddingBottom: '10px',
    textTransform: 'none',
    width: '100%',
    '&:hover': {
      backgroundColor: '#403D2F',
      boxShadow: 'none',
    },
  },
});

// Customizable Area End