import React from "react";

// Customizable Area Start
import {
  createTheme,
} from "@mui/material/styles";
import ForgotPasswordController, {
    Props,
  } from "./ForgotPasswordController";
  import { styled } from "@mui/system";
import { Modal, Box,TextField,Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import OTPModal from "../../../components/src/popups/ForgotPasswordOtpPopUp.web";
import ResetPassModal from "../../../components/src/popups/resetPasswordPopUp.web"
import PasswordUpdatedPopUp from "../../../components/src/popups/PasswordUpdatedPopUp.web";
// Customizable Area End

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
const FooterButton = styled("button")({
  width: "360px",
  height: "56px",
  padding: "16px 0px",
  borderRadius: "8px",
  color: "white",
  border: "none",
  backgroundColor: "#53503F",
  fontFamily: "'Montserrat', sans-serif",
  fontSize: "18px",
  fontWeight: 800,
  cursor: "pointer",
});
const MyTextField = styled(TextField)(({ theme }) => ({
  width: "360px",
  boxSizing: "border-box",
  height: "56px",
  justifyContent: "center",
  padding: "10px 8px",
  gap: 8,
  borderRadius: "8px",
  borderWidth: "0.2px 0 0 0",
  backgroundColor: "white",
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: "none",
      width: "100%",
    },
  },
  [theme.breakpoints.down('md')]: {
    width: "300px",
  },
  [theme.breakpoints.down('sm')]: {
    width: "100%",
  },
}));
// Customizable Area End

export default class ForgotPasswordWeb extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  
  // Customizable Area End

  render() {
     // Customizable Area Start
      // Customizable Area End
    return (
      // Customizable Area Start
      //Merge Engine DefaultContainer
      <>
        <Modal
          open={this.props.openForgotModal as boolean}
          onClose={this.props.handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{              
            maxWidth: '100%',
            backdropFilter: "blur(12px)",
            backgroundColor: "rgba(0, 0, 0, 0.3)",
        }}
        >
          <Box
            sx={{
              boxSizing: "border-box",
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              bgcolor: "#EFEEE9",
              boxShadow: 24,
              p: 4,
              borderRadius: "8px",
              maxWidth: "460px",
              width: "460px",
              outline: "none",
            }}
          >
            <div
              onClick={this.props.handleClose}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <CloseIcon
                style={{ height: "24px", width: "24px", float: "right" }}
              />
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography
                id="modal-modal-title"
                variant="h6"
                style={{
                  fontFamily: "'Montserrat', sans-serif",
                  fontSize: "28px",
                  fontWeight: 800,
                  lineHeight: "32px",
                  letterSpacing: "-0.11999999731779099px",
                  textAlign: "center",
                  color: "#53503F",
                }}
                component="h2"
              >
                Forgot Password?
              </Typography>
              <Typography
                id="modal-modal-description"
                style={{
                  marginTop: "25px",
                  fontFamily: "'Montserrat', sans-serif",
                  fontSize: "16px",
                  fontWeight: 400,
                  lineHeight: "24px",
                  letterSpacing: "-0.11999999731779099px",
                  textAlign: "center",
                  maxWidth: "299px",
                  wordWrap: "break-word",
                  whiteSpace: "normal",
                  color: "#53503F",
                }}
                sx={{ mt: 2 }}
              >
                Please enter your email address to receive a verification code
              </Typography>
              <div
                style={{
                  marginTop: "35px",
                  marginBottom: "15px",
                  display: "flex",
                  flexDirection: "column",
                  gap: "4px",
                }}
              >
                <label
                  htmlFor="email"
                  style={{
                    fontSize: "16px",
                    lineHeight: "24px",
                    fontFamily: "'Montserrat', sans-serif",
                    fontWeight: 800,
                    color: "#53503F",
                  }}
                >
                  Email
                </label>
                <MyTextField
                  placeholder="Your email"
                  id="email"
                  type="email"
                  required
                  value={this.state.forgotEmailForOtp}
                  onChange={(e: any) => this.handleforgotEmailChange(e)}
                />
                {this.state.forgotEmailErr && (
                  <p
                    style={{
                      color: "#DC2626",
                      fontFamily: "'Montserrat', sans-serif",
                      fontSize: "12px",
                      fontWeight: 800,
                      lineHeight: "18px",
                      marginBottom: this.state.forgotEmailErr ? "-15px" : "0px",
                    }}
                  >
                    {this.state.forgotEmailErr}
                  </p>
                )}
              </div>
              <FooterButton
                style={{ marginTop: "30px", marginBottom: "15px" }}
                onClick={this.handleForgotEmailSubmit}
              >
                Send
              </FooterButton>
            </div>
          </Box>
        </Modal>
        {this.state.openOtpModal && (
          <OTPModal
            otpErr={this.state.otpErr}
            handleCloseOtpModal={this.handleCloseOtpModal}
            openOtpModal={this.state.openOtpModal}
            handleSubmitOtp={this.handleSubmitOtp}
            handleResendOtp={this.handleForgotEmailSubmit}
          />
        )}
        {this.state.resetOtpModal && (
          <ResetPassModal
            handlePassword={this.handlePasswordChange}
            handleClose={this.handRsetModal}
            handleNewConfPasswordShowHide={this.handleNewConfPasswordShowHide}
            handleNewPasswordShowHide={this.handleNewPasswordShowHide}
            handleSubmit={this.handleSubmit}
            newConfErr={this.state.newConfErr}
            newConfPassword={this.state.newConfPassword}
            newPassErr={this.state.newPassErr}
            newPassword={this.state.newPassword}
            resetOtpModal={this.state.resetOtpModal}
            showConfPass={this.state.showConfPass}
            showNewPass={this.state.showNewPass}
          />
        )}

        {this.state.ResetPassSuccessModal && <PasswordUpdatedPopUp 
        handleClose={this.handleResetSuccesModal} 
        resetSuccessModal={this.state.ResetPassSuccessModal}/>}
      </>
      //Merge Engine End DefaultContainer
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
    textColor: {
        color: 'red'
    }
};
// Customizable Area End