import React from "react";
import { styled } from "@mui/system";
import { Modal, Box, Typography, IconButton, DialogActions } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import TermsConditionsController from "../../blocks/termsconditions/src/TermsConditionsController";


const ScrollableContent = styled(Box)({
  width: '103%',
  boxSizing: "border-box",
  paddingLeft: "16px",
  minHeight: '300px',
  overflowY: 'auto',
  borderRadius: '8px',
  paddingRight: "19px",
  // backgroundColor: '#EFEEE9',
  '&::-webkit-scrollbar': {
    width: '7px',
  },
  '&::-webkit-scrollbar-track': {
    background: '#f9f9f9',
    borderRadius: "8px"
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#53503F',
    borderRadius: '8px',
  },
  '&::-webkit-scrollbar-thumb:hover': {
    background: '#53503F',
  },
});

const CustomModal = styled(Box)(({ theme }) => ({
  width: "600px",
  height: "750px",
  boxSizing: "border-box",
  background:"#EFEEE9",
  padding: "20px",
  gap: "10px",
  borderRadius: "4px",
  backgroundColor: "white",
  position: "relative",
  margin: "auto",
  top: "50%",
  transform: "translateY(-50%)",
  display: "flex",
  flexDirection: "column",
}));

const ModalHeading = styled(Typography)({
  textAlign: "left",
  color: "#53503F",
  fontFamily: "'Montserrat', sans-serif",
  fontSize: "24px",
  fontWeight: 600,
});

const CloseButton = styled(IconButton)({
  position: "absolute",
  top: "35px",
  right: "10px",
});

const FooterButton = styled("button")({
  position:"absolute",
  bottom:"45px",
  left:"112px",
  width: "360px",
  height: "56px",
  padding: "16px 0px",
  borderRadius: "8px",
  color: "white",
  border: "none",
  backgroundColor: "#53503F",
  fontFamily: "'Montserrat', sans-serif",
  fontSize: "18px",
  fontWeight: 800,
  cursor: "pointer",
});
export default class TermsAndPolicyModal extends TermsConditionsController {
  constructor(props:any) {
    super(props);
    // Customizable Area Start
  // Customizable Area End
    };
    // Customizable Area Start
    // Customizable Area End

render() {
  // Customizable Area Start
  const {openModal}=this.state
  return (
    <>
      <Modal
        sx={{
          maxWidth: "100%",
          backdropFilter: "blur(12px)",
          backgroundColor: "rgba(0, 0, 0, 0.4)",
        }}
        open={this.props.openModal}
        onClose={this.props.handleClose}
      >
        <CustomModal>
          <div
            style={{
              marginTop: "20px",
              display: "flex",
              justifyContent: "space-between",
              padding: "0px 10px",
              alignItems: "center",
            }}
          >
            <ModalHeading variant="h6">{this.props?.label}</ModalHeading>{" "}
            {/* Use modalTitle here */}
            <CloseButton onClick={this.props.handleClose}>
              <CloseIcon />
            </CloseButton>
          </div>
          <ScrollableContent>
            <Typography
              style={{
                fontFamily: "'Montserrat', sans-serif",
                fontSize: "16px",
                fontWeight: 400,
                color: "#53503F",
              }}
              dangerouslySetInnerHTML={{
                __html:
                  this.props?.label == "Privacy Policy"
                    ? this.state.modalContentPrivacyPolicy
                    : this.state.modalContentTermsconditions,
              }}
            />
            {/* <DialogActions style={{ background:"transparent",display: "flex", justifyContent: "center", width: "100%", backgroundColor: "white" }}> */}
            <FooterButton onClick={this.props.handleClose}>Close</FooterButton>
            {/* </DialogActions> */}
          </ScrollableContent>
        </CustomModal>
      </Modal>
    </>
  );

  // Customizable Area End
}
}


  

